import Dropdown from 'components/Dropdown';
import Flex, { Direction, Horizontal, Vertical } from 'components/Flex';
import H4 from 'components/H4';
import TargetSizeBar from 'components/TargetSizeBar/index';
import IReport, { IDataTarget } from 'domains/reports/types';
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import * as reportResultActions from 'store/actions/reportResult';
import {
  getTargetData,
  getTargetPercentage,
  getTargetUniverse,
  getUniverseLabel,
  State,
} from 'store/selectors/targetResult';
import { getDateRangeFromQueryFilters } from 'components/QueryBuilder/adapters';
import { parseISO } from 'date-fns/fp';
import { formatLocale } from 'helpers/date';
import { TARGET_RESULT_ALLOWED_METRICS } from 'store/constants';
import { BreakdownChartTabs } from './BreakdownCharts.component';
import * as constants from './constants';

interface ConnectedProps {
  targetData: IDataTarget;
  universeName: string;
  targetUniverse: number;
  targetSize: number;
}

export interface ITargetResultProps extends ConnectedProps {
  resetTargetResult: () => void;
  isLoading?: boolean;
  path: string;
  lastValidReport: IReport | null;
}

const TargetResult: FunctionComponent<ITargetResultProps> = ({
  targetData,
  universeName,
  targetUniverse,
  targetSize,
  resetTargetResult,
  isLoading,
  path,
  lastValidReport,
}): ReactElement => {
  const DEFAULT_STATE = {
    selectedMetric: constants.LOCAL_UNIVERSE_METRICS,
  };

  const allowedMetrics = TARGET_RESULT_ALLOWED_METRICS;

  const [state, setState] = React.useState<State>(DEFAULT_STATE);

  useEffect(
    () => () => {
      resetTargetResult();
    },
    [resetTargetResult],
  );

  const onChange = useCallback((metrics: string) => {
    switch (metrics) {
      case constants.LOCAL_POPULATION_INDEX_METRICS: {
        setState({
          selectedMetric: metrics,
        });
        break;
      }
      default: {
        setState(DEFAULT_STATE);
      }
    }
  }, []);

  const lastValidBreakdownToggle =
    lastValidReport?.query.includeTargetBreakdown;

  const { startDate, endDate } = getDateRangeFromQueryFilters(
    lastValidReport?.query.filters.children,
  );

  const formatStartDate = formatLocale(parseISO(startDate));
  const formatEndDate = formatLocale(parseISO(endDate));

  return (
    <div className={constants.reportFormResultsClass}>
      <Flex
        direction={Direction.column}
        horizontal={Horizontal.left}
        vertical={Vertical.top}
      >
        <H4>{constants.LOCALE_TARGET_SIZE}</H4>
        <div style={{ maxWidth: '680px', minWidth: '50%' }}>
          <TargetSizeBar value={targetSize} />
        </div>
        <p>
          <span style={{ marginRight: '.25rem' }}>
            Selected audience includes{' '}
            <span style={{ fontWeight: 'bold' }}>
              {Math.trunc(targetUniverse).toLocaleString()}
            </span>{' '}
            Households;
          </span>
          <span>
            <span style={{ color: '#77bd22', fontWeight: 'bold' }}>
              {targetSize}%
            </span>{' '}
            of {universeName}
          </span>
        </p>
        {lastValidBreakdownToggle && targetData && (
          <>
            <H4>{constants.LOCALE_TARGET_BREAKDOWN}</H4>
            <div>
              <Dropdown
                onChange={onChange}
                options={allowedMetrics}
                value={state.selectedMetric}
              />
            </div>
            <BreakdownChartTabs
              targetData={targetData}
              selectedMetric={state.selectedMetric}
              path={path}
              startDate={formatStartDate}
              endDate={formatEndDate}
              universeName={universeName}
            />
          </>
        )}
      </Flex>
      {lastValidBreakdownToggle && !isLoading && !targetData && (
        <div className={constants.reportFormNoDataClass}>
          <Flex vertical={Vertical.top} horizontal={Horizontal.center}>
            {constants.LOCALE_NO_TARGET_DATA}
          </Flex>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: State): ConnectedProps => ({
  targetData: getTargetData(state),
  universeName: getUniverseLabel(state),
  targetUniverse: getTargetUniverse(state),
  targetSize: getTargetPercentage(state),
});

const mapDispatchToProps = {
  resetTargetResult: reportResultActions.resetTargetResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(TargetResult);
