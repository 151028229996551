import {
  LOCALE_DATE_RANGE_LABEL,
  LOCALE_EXPERIAN_BREAKOUT_PLACEHOLDER,
  LOCALE_EXPERIAN_BREAKOUTS,
  LOCALE_PLACEIQ_LABEL,
} from 'components/QueryBuilder/locale';
import MultipleDropdownSelect from 'components/MultipleDropdownSelect';
import { isBaseReportSaving } from 'domains/reports/adapters/rulesets';
import QueryBuilderItem from 'components/QueryBuilder/components/QueryBuilderItem';
import React, { useCallback, useMemo, useState } from 'react';
import { compact, find, flow, get, isEqual, map } from 'lodash/fp';
import { getByIdFromCollection, toTextValuePairs } from 'helpers/types';
import ITextValue from 'types/textValue';
import IDimension from 'domains/dimensions/types';
import { IFilterValuePayload, RuleFilter } from 'types/filter';
import IReport, { IBusinessData } from 'domains/reports/types';
import State from 'types/state';
import * as businessDataSelectors from 'store/selectors/businessData';
import * as reportActions from 'store/actions/report';
import * as reportSelectors from 'store/selectors/report';
import * as userSelectors from 'store/selectors/user';
import { connect } from 'react-redux';
import useFetch from 'hooks/useFetch';
import FetchMethod from 'types/fetchMethod';
import { Mode } from 'types/query';
import { showErrorToast } from 'helpers/general';
import { getDateRangeFromQueryFilters } from 'components/QueryBuilder/adapters';
import { parseISO } from 'date-fns';
import { formatLocale } from 'helpers/date';
import Flex, { Horizontal, Vertical } from '../Flex';
import Dropdown from '../Dropdown';
import {
  InputTestId,
  subItemFieldClass,
  subItemOperatorClass,
  subItemValueClass,
} from '../RulesBuilder/components/RulesBuilderItem';
import { Type as InputType } from '../Input';
import { DropdownSearchQueryProvider } from '../DropdownSearch';
import { getTargetAvailableBreakouts } from '../../domains/reports/adapters/general';
import useUserPermissions from '../../hooks/useUserPermissions';

interface BreakoutCategoriesRulesComponentProps {
  businessData: IBusinessData;
  availableDimensions: IDimension[];
  experianBreakouts: RuleFilter[];
  experianPIQ: RuleFilter[];
  handleExperianBreakoutsChange: (experianBreakouts: ITextValue[]) => void;
  handleExperianPIQChange: (
    value: (ITextValue | string) | (ITextValue | string)[],
    field: string,
    key?: keyof RuleFilter,
  ) => void;
  disabled?: boolean;
  isRunLaunched: boolean;
  report: IReport;
  selectedClient: string;
  email: string;
  queryMode: Mode;
  daypart_dataset_id: string;
}

type PIQDimensionTypes = {
  PIQDimension: IDimension | undefined;
  PIQChainDimension: IDimension | undefined;
  PIQCategoryDimension: IDimension | undefined;
};
type PIQFilterValues = {
  categoryFilter: RuleFilter | undefined;
  chainFilter: RuleFilter | undefined;
};

const getPIQDimensions = (
  availableDimensions: IDimension[],
): PIQDimensionTypes => {
  const PIQDimension = getByIdFromCollection<IDimension>(
    'PLACEIQ',
    availableDimensions,
  );
  const dimension = get('dimensions', PIQDimension) as IDimension[];
  const PIQCategoryDimension = getByIdFromCollection<IDimension>(
    'PIQ_CATEGORY',
    dimension,
  );
  const PIQChainDimension = getByIdFromCollection<IDimension>(
    'PIQ_CHAIN',
    dimension,
  );
  return { PIQDimension, PIQChainDimension, PIQCategoryDimension };
};
const getPIQFilterValues = (PIQFilters: RuleFilter[]): PIQFilterValues => {
  const categoryFilter = getByIdFromCollection('PIQ_CATEGORY', PIQFilters);
  const chainFilter = getByIdFromCollection('PIQ_CHAIN', PIQFilters);

  return {
    categoryFilter,
    chainFilter,
  };
};

const operators: ITextValue[] = [
  {
    text: 'breakout on',
    value: 'BREAKOUT_ON',
  },
];

const getBreakoutDimensions = flow(
  getTargetAvailableBreakouts,
  toTextValuePairs,
) as (d: Partial<State>) => ITextValue[];

const getSelectedBreakouts = (
  demographicsOptions: ITextValue[],
  experianBreakouts: RuleFilter[],
): ITextValue[] =>
  compact(
    map(
      (breakout: RuleFilter) =>
        find(
          (option: ITextValue) =>
            isEqual(get('value', option), get('field', breakout)),
          demographicsOptions ?? [],
        ),
      experianBreakouts ?? [],
    ) ?? [],
  );

const BreakoutCategoriesRulesComponent = ({
  businessData,
  availableDimensions,
  experianBreakouts,
  experianPIQ,
  handleExperianBreakoutsChange,
  disabled,
  isRunLaunched,
  report,
  queryMode,
  selectedClient,
  email,
  handleExperianPIQChange,
  daypart_dataset_id,
}: BreakoutCategoriesRulesComponentProps): JSX.Element => {
  const [loading, setLoading] = useState({
    PIQ_CATEGORY: false,
    PIQ_CHAIN: false,
  });
  const [categoryFilters, setCategoryFilters] = useState<ITextValue[]>([]);
  const { doFetch } = useFetch<ITextValue>();

  const demographicsOptions = useMemo(
    () => getBreakoutDimensions({ businessData } as Partial<State>),
    [businessData],
  );

  const selectedExperianBreakouts = useMemo(
    () => getSelectedBreakouts(demographicsOptions, experianBreakouts),
    [experianBreakouts, demographicsOptions],
  ) as ITextValue[];

  const { PIQChainDimension, PIQCategoryDimension, PIQDimension } = useMemo(
    () => getPIQDimensions(availableDimensions),
    [availableDimensions],
  );
  const { categoryFilter, chainFilter } = useMemo(
    () => getPIQFilterValues(experianPIQ),
    [experianPIQ],
  );
  const { checkPermissions } = useUserPermissions();

  const canSeeExperianBreakouts = checkPermissions('targets.dimensions::view');

  const retrieveValues: (childField: string) => DropdownSearchQueryProvider =
    useCallback(
      (childField: string) => async (valueFilter, lineBegin, lineEnd) =>
        new Promise((resolve, reject) => {
          setLoading({ ...loading, [childField]: true });
          doFetch({
            endpoint: '/filterValues',
            payload: {
              userEmail: email,
              datasetIds: [],
              field: PIQDimension?.id,
              mode: queryMode,
              lineBegin,
              lineEnd,
              valueFilter,
              childField,
              filterRules: [],
              selectedClient,
              daypart_dataset_id,
            } as IFilterValuePayload,
            method: FetchMethod.POST,
            onError: (e) => {
              setLoading({ ...loading, [childField]: false });
              setCategoryFilters([]);
              reject(e);
              showErrorToast(e.message ?? 'Cannot retrieve rules values');
            },
            onSuccess: (d) => {
              setLoading({ ...loading, [childField]: false });
              setCategoryFilters(d);
              resolve(d);
            },
          });
        }),
      [email, queryMode, selectedClient],
    );

  const retrieveCategoryValues = useCallback(
    retrieveValues(get('id', categoryFilter) ?? ''),
    [get('id', categoryFilter)],
  );

  const retrieveChainValues = useCallback(
    retrieveValues(get('id', chainFilter) ?? ''),
    [get('id', chainFilter)],
  );

  const { startDate, endDate } = getDateRangeFromQueryFilters(
    report.query?.filters.children,
  );

  const formatStartDate = formatLocale(parseISO(startDate));
  const formatEndDate = formatLocale(parseISO(endDate));

  return canSeeExperianBreakouts ? (
    <>
      <QueryBuilderItem label={LOCALE_EXPERIAN_BREAKOUTS}>
        <MultipleDropdownSelect
          options={demographicsOptions}
          withSearch
          minimumChipsSelected={1}
          fetchedOptions={false}
          pageSize={demographicsOptions?.length ?? 10}
          placeholder={LOCALE_EXPERIAN_BREAKOUT_PLACEHOLDER}
          onChange={handleExperianBreakoutsChange}
          selected={selectedExperianBreakouts ?? []}
          disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
          trackingId="breakouts_categorie_filter"
          testId="breakouts_categorie_filter"
        />
      </QueryBuilderItem>
      <QueryBuilderItem label={LOCALE_PLACEIQ_LABEL}>
        <b>{LOCALE_DATE_RANGE_LABEL}:</b> {formatStartDate} - {formatEndDate}
        <Flex
          horizontal={Horizontal.left}
          className="mb-3"
          vertical={Vertical.bottom}
        >
          <div className={subItemFieldClass}>
            <Dropdown
              value={get('id', categoryFilter)}
              disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
              fixed
              testId={InputTestId.parentRule}
              trackingId="category_field"
              options={toTextValuePairs(compact([PIQCategoryDimension]))}
            />
          </div>
          <div className={subItemOperatorClass}>
            <Dropdown
              value={get('operator', categoryFilter)}
              disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
              testId={InputTestId.operator}
              trackingId="rule_operator"
              placeholder="Select an operator"
              options={operators}
              onChange={(value: string) =>
                handleExperianPIQChange(
                  value,
                  get('field', categoryFilter) as string,
                  'operator',
                )
              }
            />
          </div>
          <div className={subItemValueClass}>
            <MultipleDropdownSelect
              queryProvider={retrieveCategoryValues}
              fetchedOptions
              loading={loading.PIQ_CATEGORY}
              disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
              testId={InputTestId.ruleValue}
              trackingId="rule_value"
              placeholder={
                loading.PIQ_CATEGORY ? 'Loading values' : 'Select value'
              }
              withSearch
              options={categoryFilters}
              selected={get('value', categoryFilter) as ITextValue[]}
              onChange={(value: ITextValue[]) =>
                handleExperianPIQChange(
                  value,
                  get('field', categoryFilter) as string,
                  'value',
                )
              }
              type={InputType.search}
            />
          </div>
        </Flex>
        <Flex horizontal={Horizontal.left} vertical={Vertical.bottom}>
          <div className={subItemFieldClass}>
            <Dropdown
              value={get('id', chainFilter)}
              disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
              fixed
              testId={InputTestId.parentRule}
              trackingId="chain_field"
              options={toTextValuePairs(compact([PIQChainDimension]))}
            />
          </div>
          <div className={subItemOperatorClass}>
            <Dropdown
              value={get('operator', chainFilter)}
              disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
              testId={InputTestId.operator}
              trackingId="rule_operator"
              placeholder="Select an operator"
              options={operators}
              onChange={(value: string) =>
                handleExperianPIQChange(
                  value,
                  get('field', chainFilter) as string,
                  'operator',
                )
              }
            />
          </div>
          <div className={subItemValueClass}>
            <MultipleDropdownSelect
              queryProvider={retrieveChainValues}
              fetchedOptions
              loading={loading.PIQ_CHAIN}
              disabled={isRunLaunched || isBaseReportSaving(report) || disabled}
              testId={InputTestId.ruleValue}
              trackingId="rule_value"
              withSearch
              placeholder={
                loading.PIQ_CHAIN ? 'Loading values' : 'Select value'
              }
              options={categoryFilters}
              selected={get('value', chainFilter) as ITextValue[]}
              onChange={(value: ITextValue[]) =>
                handleExperianPIQChange(
                  value,
                  get('field', chainFilter) as string,
                  'value',
                )
              }
              type={InputType.search}
            />
          </div>
        </Flex>
      </QueryBuilderItem>
    </>
  ) : (
    <></>
  );
};

const mapStateToProps = (
  state: State,
): Pick<
  BreakoutCategoriesRulesComponentProps,
  | 'businessData'
  | 'experianBreakouts'
  | 'report'
  | 'availableDimensions'
  | 'experianPIQ'
  | 'queryMode'
  | 'selectedClient'
  | 'email'
  | 'daypart_dataset_id'
> => ({
  businessData: businessDataSelectors.getBusinessData(state),
  availableDimensions: businessDataSelectors.getAvailableDimensions(state),
  experianBreakouts: reportSelectors.getExperianBreakoutFilters(state),
  experianPIQ: reportSelectors.getExperianPIQFilterChildren(state),
  report: reportSelectors.getReport(state),
  queryMode: reportSelectors.getModeFromQuery(state) as Mode,
  selectedClient: userSelectors.getSelectedClient(state),
  email: userSelectors.getEmail(state),
  daypart_dataset_id: reportSelectors.getQueryDaypartDatasetId(state),
});

const mapDispatchToProps: Pick<
  BreakoutCategoriesRulesComponentProps,
  'handleExperianBreakoutsChange' | 'handleExperianPIQChange'
> = {
  handleExperianBreakoutsChange: reportActions.handleExperianBreakoutsChange,
  handleExperianPIQChange: reportActions.handleExperianPIQChange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BreakoutCategoriesRulesComponent);
